import React, { useState } from "react";
import Wrapper from "../../assets/wrappers/BoundaryAnalysis";
import ReCAPTCHA from "react-google-recaptcha";
import { useAppContext } from "../../context/appContext";

const AddTestimony = () => {
  const { createTestimony, user } = useAppContext();
  const [formData, setFormData] = useState({
    description: "",
    name: `${user.name} ${user.lastName}`,
  });
  const [captchaValue, setCaptchaValue] = useState(null); 
  const [isCaptchaValid, setIsCaptchaValid] = useState(false); 
  const [isTestimonyAdded, setIsTestimonyAdded] = useState(false);
  const recaptchaRef = React.createRef();


  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 500) {
      setFormData({ ...formData, [event.target.name]: inputValue });
    }
  };

  const resetForm = () => {
    setFormData({
      description: "",
      name: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if reCAPTCHA is valid before submitting
    if (isCaptchaValid) {
      createTestimony(formData);
      setIsTestimonyAdded(true);
      resetForm();
    } else {
      // Handle reCAPTCHA validation error
      alert("Please complete the reCAPTCHA validation.");
    }
  };

  // Callback function to handle reCAPTCHA verification
  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
  };

  return (
    <Wrapper>
      <div className="formbold-main-wrapper">
        <div className="formbold-form-wrapper">
          <form onSubmit={handleSubmit}>
      
            <div className="formbold-input-flex" style={{ color: "red" }}>
              Please Do not automate. This is for testimonial only
            </div>
            <div>
              <label htmlFor="description" className="formbold-form-label"></label>
              <textarea
                rows="14"
                maxLength="300" // Added maxLength attribute
                type="text"
                name="description"
                id="description"
                placeholder="Your Testimonial. Please let the world know how we did and how well you will you recommend the course."
                className="formbold-form-input"
                value={formData.description}
                onChange={handleChange}
              />
              <div style={{ color: "gray", fontSize: "12px" }}>
                {formData.description.length}/300 characters
              </div>
            </div>
            {formData.description.length > 300 && (
              <div style={{ color: "red" }}>Maximum character limit exceeded.</div>
            )}
            {isTestimonyAdded && (
              <div style={{ color: "green" }}>Testimonial has been added.</div>
            )}
              <div className="formbold-recaptcha-wrapper">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lewo5QoAAAAAPmYBq27KjXA-C3l51uCSEmyd5zp" // Replace with your actual reCAPTCHA site key
                onChange={handleRecaptchaChange}
              />
            </div>
            <button type="submit" className="formbold-btn" disabled={!isCaptchaValid}>
              Post
            </button>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default AddTestimony;
