import moment from 'moment'
import { FaLocationArrow, FaBriefcase, FaCalendarAlt,FaMoneyBillWave } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useAppContext } from '../context/appContext'
import Wrapper from '../assets/wrappers/Job'
import JobInfo from './JobInfo'

const AllJob = ({
  _id,
  position,
  company,
  jobLocation,
  jobType,
  createdAt,
  status,
  jobSalary,
  payType,
}) => {
  const { setEditJob, deleteJob } = useAppContext()

  let date = moment(createdAt)
  date = date.format('MMM Do, YYYY')
  return (
    <Wrapper>
      <header>
        <div className='main-icon'>{company.charAt(0)}</div>
        <div className='info'>
          <h5 id='position'>{position}</h5>
          <p id='company'>{company}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <JobInfo icon={<FaLocationArrow />} text={jobLocation} />
          <JobInfo icon={<FaCalendarAlt />} text={date} />
          <JobInfo icon={<FaBriefcase />} text={jobType} />
          <JobInfo icon={<FaMoneyBillWave />} text={`£${jobSalary}/${payType}`} />
                  
          <div className={`status ${status}`}>{status}</div>
        </div>
        <footer>
          <div className='actions'>
            {/* <button
              id='viewJob'
              type='button'
              className='btn edit-btn'
              onClick={() => deleteJob(_id)}
            >
              View
            </button> */}
                      

            {/* <Link
              id='editJob'
              to='/add-job'
              className='btn edit-btn'
              onClick={() => setEditJob(_id)}
            >
              Edit
            </Link>
            <button
              id='deleteJob'
              type='button'
              className='btn delete-btn'
              onClick={() => deleteJob(_id)}
            >
              Delete
            </button> */}
          </div>
        </footer>
      </div>
    </Wrapper>
  )
}

export default AllJob
