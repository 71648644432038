import main from '../assets/images/test.png'
import Wrapper from '../assets/wrappers/LandingPage'
import { Logo } from '../components'
import { Link } from 'react-router-dom'
import Navbar from '../components/HomePage/Navbar'
const Landing = () => {
  return (
    <Wrapper>
      <nav>
        <Navbar />
      </nav>
      <div className='container page'>
        {/* info */}
        <div className='info'>
          <br></br>
          <h1>
            QA <span>Squadron</span> Portal
          </h1>
          <p>
            Welcome to QA-Squadron, your one-stop-shop for all your software testing needs! We provide a
            comprehensive range of learning materials and course material to help you enhance your software testing skills.
            Whether you're just starting out or are an experienced tester, we have something for everyone.
            In addition to our learning resources, we also offer a test area where you can practice your automation and manual testing skills.
            Our mission is to help you become the best tester you can be.
            So, come aboard our QA-Squadron and join us on a journey towards excellence in software testing
          </p>
          <Link to='/login' className='btn btn-hero'>
            Login
          </Link>
        </div>
        <img src={main} alt='job hunt' className='img main-img' />
      </div>
    </Wrapper>
  )
}

export default Landing
