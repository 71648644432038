import { Link } from 'react-router-dom';
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/modules'
import SearchLesson from './../../components/SearchLesson'
import ModulePaginate from './../../components/ModulePaginate'
import {useEffect } from 'react'

const AllLessons = () => {
    const {
        getModule,
        modules,
        deleteModule,
        user,
      getOneModule,
      modulePage,
      lessontitle,
      lessonCategory,
        numOfPagesModule
  } = useAppContext()
  useEffect(() => {
    getModule()
    // eslint-disable-next-line
  }, [modulePage,lessontitle,lessonCategory])

  return (
    <Wrapper>
      <SearchLesson/>
        <div className="items-wrapper">
        {modules.map((item) => (
             <div className="item-card" key={item._id}>
          <h2 className="item-title">{item.title}</h2>
           {/* <p className="item-description">{`${item.description.slice(0, 50)}...`}</p> */}
            <Link to={`/dashboard/module`} className="item-link" onClick={() => getOneModule(item._id)}>Read More</Link>
              {user.role === "admin" && (
        <>
          <button className='delete-btn'
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this module?")) {
                deleteModule(item._id);
              }
            }}
          >
            Delete
          </button>
        </>
      )}
        </div>
        ))}
      </div>
      {numOfPagesModule > 0 && <ModulePaginate />}
    </Wrapper>
  )
}

export default AllLessons
