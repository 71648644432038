import Navbar from "../components/HomePage/Navbar";
import Contact from "../components/HomePage/contactForm";

export default function ContactUs() {
    return (
        <>
            <Navbar/>
            <Contact/>
        </>
    )
}