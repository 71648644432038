import {useState } from 'react'
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/BoundaryAnalysis'

const BoundaryAnalysis = () => {

    const initialFormData = {
        loanAmount:0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        dob: "",
        age: "",
        employment: "",
        isMilitary: false,
        isFamilyMilitary: false,
      isVeteran: false,
        status:"Approved",
    salary: 0,
  };
  const [errors, setErrors] = useState({});
 const {createLoanApplication} = useAppContext()
  const [formData, setFormData] = useState(initialFormData);
  
  const clearError = () => {
  setTimeout(() => {
    setError('');
  }, 5000);
};

const resetForm = () => {
  setFormData(initialFormData);
}
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };

const handleSubmit = (event) => {
  event.preventDefault();
  if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.address) {
    setError("formError", "Please fill form before submitting");
    clearError()
    return;
  }
  if (!formData.dob) {
    setError("dob", "Please don't be shy to share your Date of birth.");
    clearError()
    return;
  }
  const agefromdob = getAge(formData.dob);
    if (agefromdob < 18 ) {
      setError("dob", "Come back in a couple of years");
      clearError()
    return;
  }
    if (agefromdob > 65) {
      setError("dob", "Sorry Mate you are too old ");
      clearError()
    return;
  }
  if (formData.age < 18 || formData.age > 65) {
    setError("age", "You must be over 18 years old and under 65 years old.");
    clearError()
    return;
  }
  // Validate minimum income
  if (formData.salary < 20000) {
    setError("salary", "You must have a minimum annual income of £20000 or more to be eligible for a loan.");
    clearError()
    return;
  }
  // Validate employment
  if (!formData.employment) {
    setError("employment", "You must select your employment status.");
    clearError()
    return;
  }
  if (formData.employment === "unemployed" || formData.employment === "student") {
    setError("employment", "You might not be able to get a loan mate.");
    clearError()
    return;
  }

  // Other validations (such as credit history and ability to repay) can be added here
  const randomStatus = Math.random() < 0.5 ? "Approved" : "Rejected";
  setFormData({ ...formData, status: randomStatus });
  createLoanApplication(formData);
  resetForm();
  // send form data to server or perform any other necessary action
};

const setError = (field, message) => {
  setErrors({ ...errors, [field]: message });
};

// Utility function to calculate age based on date of birth
const getAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};


    return (
          <Wrapper>
            <div className="formbold-main-wrapper">
            <div className="formbold-form-wrapper">
                
                {/* <img src="your-image-url-here.jpg"> */}

                 <form onSubmit={handleSubmit}>
                <div className="formbold-form-title">
                    <h2 className="">Loan Application form</h2>  
                        <p> Please ensure that you meet the following conditions before applying for a loan with us:</p>
                            <ol>
                                <li>Applicants must be over 18 years old and under 65 years old.</li>
                                <li>Applicants must have a minimum annual income of £20000 or more to be eligible for a loan.</li>
                                <li>Applicants must be currently employed in a full-time, part-time, or benefits position.</li>
                                <li>We will check your credit history as part of the application process. A good credit score will increase your chances of being approved for a loan.</li>
                                <li>Applicants must be able to demonstrate their ability to repay the loan on time.</li>
                            </ol>
                        </div>
                        <h2 className="">Fill the form Below to check your eligiblility</h2>

              <div className="formbold-input-flex">
        <div>
          <label htmlFor="firstName" className="formbold-form-label">
            First name
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            className="formbold-form-input"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="lastName" className="formbold-form-label">
            Last name
          </label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            className="formbold-form-input"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="formbold-input-flex">
        <div>
          <label htmlFor="email" className="formbold-form-label">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="formbold-form-input"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="phone" className="formbold-form-label">
            Phone number
          </label>
          <input
            type="text"
            name="phone"
            id="phone"
            className="formbold-form-input"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="formbold-mb-3">
        <label htmlFor="address" className="formbold-form-label">
          Street Address
        </label>
        <input
          type="text"
          name="address"
          id="address"
          className="formbold-form-input"
          value={formData.address}
          onChange={handleChange}
        />
      </div>
    <div className="formbold-mb-3">
  <label htmlFor="dob" className="formbold-form-label">
    Date of Birth
  </label>
  <input
    type="date"
    name="dob"
    id="dob"
    className="formbold-form-input"
    value={formData.dob}
    onChange={handleChange}
    />
  {errors.dob && <p className="formbold-form-error" id='dob-error'>{errors.dob}</p>}
</div>

<div className="formbold-mb-3">
  <label htmlFor="age" className="formbold-form-label">
    Age
  </label>
  <input
    type="number"
    name="age"
    id="age"
    className={`formbold-form-input ${errors.dob && 'formbold-form-error'}`}
    value={formData.age}
    onChange={handleChange}
    />
   {errors.age && <p className="formbold-form-error" id='age-error'>{errors.age}</p>}
</div>

<div className="formbold-mb-3">
  <label htmlFor="employment" className="formbold-form-label">
    Employment Type
  </label>
  <select
    name="employment"
    id="employment"
    className="formbold-form-input"
    value={formData.employment}
    onChange={handleChange}
  >
    <option value="">Select</option>
    <option value="Full-time">Full-time</option>
    <option value="Part-time">Part-time</option>
    <option value="Contractor">Contractor</option>
    <option value="Self-employed">Self-employed</option>
    <option value="Student">Student</option>
    <option value="Unemployed">Unemployed</option>
                </select>
{errors.employment && <p className="formbold-form-error" id='employment-error'>{errors.employment}</p>}
</div>

<div className="formbold-mb-3">
  <label htmlFor="salary" className="formbold-form-label">
    Salary
  </label>
  <input
    type="range"
    name="salary"
    id="salary"
    className="formbold-form-input"
    min="0"
    max="100000"
    step="100"
    value={formData.salary}
    onChange={handleChange}
  />
  <p>£ {formData.salary} per year</p>
{errors.salary && <p className="formbold-form-error" id='salary-error'>{errors.salary}</p>}
    </div>
    <div className="formbold-mb-3">
  <label htmlFor="loanAmount" className="formbold-form-label">
    How Much will you like to borrow?
  </label>
  <input
    type="number"
    name="loanAmount"
    id="loanAmount"
    className="formbold-form-input"
    value={formData.loanAmount}
    onChange={handleChange}
  />
</div>


<div className="formbold-mb-3">
  <label className="formbold-form-label">
             <h2>Check the boxes that apply to you</h2>                   
  </label>
  <div className="formbold-mb-3 formbold-checkbox-grid">
  <label htmlFor="isMilitary" className="formbold-checkbox-label">
    Armed Forces
  </label>
  <input
    type="checkbox"
    name="isMilitary"
    id="isMilitary"
    className="formbold-checkbox-input"
    checked={formData.isMilitary}
    onChange={handleChange}
  />
  
  <label htmlFor="isFamilyMilitary" className="formbold-checkbox-label">
    Family Member of Veteran
  </label>
  <input
    type="checkbox"
    name="isFamilyMilitary"
    id="isFamilyMilitary"
    className="formbold-checkbox-input"
    checked={formData.isFamilyMilitary}
    onChange={handleChange}
  />

  <label htmlFor="isveteran" className="formbold-checkbox-label">
    Veteran
  </label>
  <input
    type="checkbox"
    name="isveteran"
    id="isveteran"
    className="formbold-checkbox-input"
    checked={formData.isveteran}
    onChange={handleChange}
  />
</div>

</div>
<button id='submit-btn' type='submit' className='btn btn-block'>
    submit
</button>
    {errors.formError && <p className="formbold-form-error" id='form-error'>{errors.formError}</p>}
<div className="formbold-footer">
<p>
    &copy; 2023 QA-Squadron. All rights reserved. Made with &hearts; by
</p>
</div>
</form>
</div>
</div>
        </Wrapper>
    )
}
    


    export default BoundaryAnalysis;
