import { useState } from 'react'
import Navbar from './Navbar'
import Hero from './Hero'



export default function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">
     <Navbar/>
        <Hero/>
      
    </div>
  )
}
