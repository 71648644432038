import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/BoundaryAnalysis'
import ReactPlayer from 'react-player';
import { useEffect } from 'react';

const ModuleDetails = () => {
    const {
        description,
        title,
        readingLink,
        videoLink,
    } = useAppContext()

    function VideoPlayer({ url }) {
        return <ReactPlayer url={url} controls />;
    }

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    function handleBeforeUnload(event) {
        event.preventDefault();
        event.returnValue = '';
    }

    
    

    return (
        <Wrapper>
            <div className="formbold-main-wrapper">
                <div className="formbold-form-wrapper">

                    {/* <img src="your-image-url-here.jpg"> */}

                    <div className="formbold-form-title">
                        <h2 className="">{title}</h2>
                        <p> {description}</p>
                        {readingLink && <a href={readingLink}> Click here to read more </a>}
                    </div>
                    {videoLink && <VideoPlayer url={videoLink} />}

                    <div className="formbold-input-flex">

                    </div>

                    <div className="formbold-footer">

                    </div>
                </div>
            </div>
        </Wrapper>
    )

}

export default ModuleDetails;
