import React, { useReducer, useContext } from 'react'

import reducer from './reducer'
import axios from 'axios'
import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CREATE_JOB_BEGIN,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  CREATE_LOAN_SUCCESS,
  CREATE_LOAN_ERROR,
  GET_JOBS_BEGIN,
  GET_JOBS_SUCCESS,
  SET_EDIT_JOB,
  GET_ONE_MODULE,
  DELETE_JOB_BEGIN,
  EDIT_JOB_BEGIN,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_ERROR,
  SHOW_STATS_BEGIN,
  SHOW_STATS_SUCCESS,
  CLEAR_FILTERS,
  CHANGE_PAGE,
  MD_CHANGE_PAGE,
  GET_LOANS_SUCCESS,
  GET_LOANS_BEGIN,
  GET_USERS_BEGIN,
  GET_USERS_SUCCESS,
  GET_MODULES_SUCCESS,
  GET_TESTIMONY_SUCCESS,
  GET_TESTIMONY_BEGIN
} from './actions'

const token = localStorage.getItem('token')
const user = localStorage.getItem('user')
// const userLocation = localStorage.getItem('location')

const initialState = {
  isLoading: false,
  showAlert: false,
  alertText: '',
  alertType: '',
  user: user ? JSON.parse(user) : null,
  token: token,
  showSidebar: false,
  isEditing: false,
  editJobId: '',
  position: '',
  company: '',
  jobLocation: '',
  jobTypeOptions: ['full-time', 'part-time', 'remote', 'internship', 'contract'],
  payTypeOptions: ['Yearly', 'Hourly', 'Daily', 'Monthly'],
  jobType: 'full-time',
  statusOptions: ['open', 'closing soon', 'closed'],
  status: 'open',
  jobSalary:0,
  payType:'Yearly',
  jobs: [],
  loans: [],
  users: [],
  modules:[],
  totalJobs: 0,
  totalUsers:0,
  numOfPages: 1,
  page: 1,
  numOfPagesModule: 1,
  modulePage: 1,
  stats: {},
  monthlyApplications: [],
  search: '',
  searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  location:'',
  salary: '',
  place:'',
  sortOptions: ['latest', 'oldest',],
  priceOptions: ['£0 to £20,000', '£30,000 to £50,000', '£60,000 to £80,000', '£90,000 to £120,000', '£130,000 +'],
  courseOptions:['manual', 'automation','all'],
  roleOptions: ['admin', 'student'],
  lessonCategoryOption:['Learning-material','Weekly-lesson'],
  description: '',
  lessontitle: '',
  lessonCategory:'',
  videoLink:'',
  readingLink:'',
  title:'',
  category: '',
  testimony:[]

}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // axios
  const authFetch = axios.create({
     baseURL: 'https://qa-squadron.onrender.com/api/v1',
    // baseURL: 'http://localhost:8001/api/v1',
  })
  // request

  authFetch.interceptors.request.use(
    (config) => {
      config.headers['Authorization'] = `Bearer ${state.token}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  // response

  authFetch.interceptors.response.use(
  response => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      logoutUser()
    }
    return Promise.reject(error);
  }
);


  const displayAlert = () => {
    dispatch({ type: DISPLAY_ALERT })
    clearAlert()
  }

  const clearAlert = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT })
    }, 3000)
  }

  const addUserToLocalStorage = ({ user, token }) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
  }

  const removeUserFromLocalStorage = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }

  const setupUser = async ({ currentUser, endPoint, alertText }) => {
    dispatch({ type: SETUP_USER_BEGIN })
    try {
      // http://ec2-52-91-116-45.compute-1.amazonaws.com:8000/api/v1
      // const { data } = await axios.post(`http://localhost:8001/api/v1/users/${endPoint}`, currentUser)
      const { data } = await axios.post(`https://qa-squadron.onrender.com/api/v1/users/${endPoint}`, currentUser)
      const user = data.data.user;
      const token = data.token;
      //const { user, token, location } = data
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, token, alertText },
      })
       addUserToLocalStorage({ user, token })
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.message },
      })
    }
    clearAlert()
  }
  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR })
  }

  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER })
    removeUserFromLocalStorage()
  }
  const updateUser = async (currentUser) => {
     dispatch({ type: UPDATE_USER_BEGIN })
    try {
          
      const { data } = await authFetch.patch('/users/updateMe', {
        currentUser
      })
      const user = data.data.user;
      const token = state.token;
      //  console.log(data);
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, token },
      })
      addUserToLocalStorage({ user,  token })
     } catch (error) {
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.message },
        })
      }
    }
    clearAlert()
  }

  const handleChange = ({ name, value }) => {
    dispatch({ type: HANDLE_CHANGE, payload: { name, value } })
  }
  const clearValues = () => {
    dispatch({ type: CLEAR_VALUES })
  }
  const createJob = async () => {
    dispatch({ type: CREATE_JOB_BEGIN })
    try {
        const { position, company, jobLocation, jobType, status, jobSalary, payType } = state
      await authFetch.post('/jobs', {
        position,
        company,
        jobLocation,
        jobType,
        payType,
        status,
        jobSalary,
      })
      dispatch({ type: CREATE_JOB_SUCCESS })
      dispatch({ type: CLEAR_VALUES })
    } catch (error) {
      if (error.response.status === 401) return
      dispatch({
        type: CREATE_JOB_ERROR,
        payload: { msg: error.response.data.message },
      })
    }
    clearAlert()
  }

  const createUser = async (newStudent, alertText) => {
    dispatch({ type: CREATE_USER_BEGIN })
    try {
       // const { name, lastName, role, email,password,passwordConfirm} = state
      //console.log(newStudent);
      await authFetch.post('/users', newStudent)
           dispatch({
            type: CREATE_USER_SUCCESS,
            payload: alertText
          })
      dispatch({ type: CLEAR_VALUES })
    } catch (error) {
      if (error.response.status === 401) return
      dispatch({
        type: CREATE_USER_ERROR,
        payload: { msg: error.response.data.message },
      })
    }
    clearAlert()
  }
  const getUsers = async () => {

    dispatch({ type: GET_USERS_BEGIN })
    try {
      const { data } = await authFetch.get('/users')
      // const { jobs, results } = data
      const totalUsers = data.results;
      const users = data.data.data;
      // console.log(jobs);
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: {
          totalUsers,
          users,
          
        },
      })
    } catch (error) {
      logoutUser()
    }
    clearAlert()
  }

  const getJobs = async () => {

    dispatch({ type: GET_JOBS_BEGIN })
    try {
      const { data } = await authFetch.get('/jobs')
      // const { jobs, results } = data
      const totalJobs = data.results;
      const jobs = data.data.data;
      // console.log(jobs);
      dispatch({
        type: GET_JOBS_SUCCESS,
        payload: {
          totalJobs,
          jobs,
          
        },
      })
    } catch (error) {
      logoutUser()
    }
    clearAlert()
  }

  const getAllJobs = async () => {
    const { page,place, search, searchStatus, searchType, sort,location,salary } = state
    let minSalary = 0;
    let maxSalary = 0;

    if (salary === '£0 to £20,000') {
      minSalary = 0;
      maxSalary = 20000;
    } else if (salary === '£30,000 to £50,000') {
      minSalary = 30000;
      maxSalary = 50000;
    } else if (salary === '£60,000 to £80,000') {
      minSalary = 60000;
      maxSalary = 80000;
    } else if (salary === '£90,000 to £120,000') {
      minSalary = 90000;
      maxSalary = 120000;
    } else if (salary === '£130,000 +') {
      minSalary = 130000;
      maxSalary = null;
    } else {
      minSalary = 0;
      maxSalary = 500000;
    }


    let url = `/jobs/all-jobs?page=${page}&city=${place}&status=${searchStatus}&jobType=${searchType}&location=${location}&sort=${sort}&search=${search}&salaryMin=${minSalary}&salaryMax=${maxSalary}`;

    dispatch({ type: GET_JOBS_BEGIN })
    try {
      //const { data } = await axios.get('http://localhost:8001/api/v1/jobs/all-jobs')
      const { data } = await authFetch(url)
      // const { jobs, results } = data
      const { jobs, totalJobs, numOfPages } = data
      // console.log(salary);
      dispatch({
        type: GET_JOBS_SUCCESS,
        payload: {
          totalJobs,
          jobs,
          numOfPages,
          
        },
      })
    } catch (error) {
      logoutUser()
    }
    clearAlert()
  }

  const setEditJob = (id) => {
    dispatch({ type: SET_EDIT_JOB, payload: { id } })
  }
  const editJob = async () => {
    dispatch({ type: EDIT_JOB_BEGIN })

    try {
      const { position, company, jobLocation, jobType, status } = state
      await authFetch.patch(`/jobs/${state.editJobId}`, {
        company,
        position,
        jobLocation,
        jobType,
        status,
      })
      dispatch({ type: EDIT_JOB_SUCCESS })
      dispatch({ type: CLEAR_VALUES })
    } catch (error) {
      if (error.response.status === 401) return
      dispatch({
        type: EDIT_JOB_ERROR,
        payload: { msg: error.response.data.msg },
      })
    }
    clearAlert()
  }
  const deleteJob = async (jobId) => {
    dispatch({ type: DELETE_JOB_BEGIN })
    try {
      await authFetch.delete(`/jobs/${jobId}`)
      getJobs()
      getAllJobs()
    } catch (error) {
      logoutUser()
    }
  }

  const createTestimony = async (formData) => {
    // console.log(formData.description);
    try {
      await authFetch.post('/testimony',formData)
     
      dispatch({ type: CLEAR_VALUES })
    } catch (error) {
      if (error.response.status === 401) return
      dispatch({
        payload: { msg: error.response.data.message },
      })
    }
    clearAlert()
      
  }
   const deleteTestimony = async () => {
      
  }
   const editTestimony = async () => {
      
  }

   const getTestimony = async () => {
      dispatch({ type: GET_TESTIMONY_BEGIN })
    try {
      const { data } = await authFetch.get('/testimony')
      // const { jobs, results } = data
      const totalJobs = data.results;
      const testimony = data.data.data;
      // console.log(jobs);
      dispatch({
        type: GET_TESTIMONY_SUCCESS,
        payload: {
          // totalJobs,
          testimony,
          
        },
      })
    } catch (error) {
      logoutUser()
    }
    clearAlert()
  }
  

    const deleteUser = async (userId) => {
    dispatch({ type: DELETE_JOB_BEGIN })
    try {
      await authFetch.delete(`/users/${userId}`)
      getUsers()
    } catch (error) {
      logoutUser()
    }
  }

  const createLoanApplication = async (application) => {
     try {
       await authFetch.post('/loan', application)
      dispatch({
            type: CREATE_LOAN_SUCCESS,
          })
          getLoanApplication()
      dispatch({ type: CLEAR_VALUES })
    } catch (error) {
      if (error.response.status === 401) return
      dispatch({
        type: CREATE_LOAN_ERROR,
        payload: { msg: error.response.data.message },
      })
    }
    clearAlert()
  }

    const getLoanApplication = async () => {

    dispatch({ type: GET_LOANS_BEGIN })
    try {
      const { data } = await authFetch.get('/loan')

      const loans = data.data;
      dispatch({
        type: GET_LOANS_SUCCESS,
        payload: {
          loans
        },
      })
    } catch (error) {
      logoutUser()
    }
    clearAlert()
  }


    const deleteLoanApplication = async (loanId,alertText) => {
     try {
       // const { name, lastName, role, email,password,passwordConfirm} = state
      //console.log(newStudent);
      await authFetch.delete(`/loan/${loanId}`)
           dispatch({
            type: CREATE_USER_SUCCESS,
            payload: alertText
          })
          getLoanApplication()
      dispatch({ type: CLEAR_VALUES })
    } catch (error) {
      if (error.response.status === 401) return
      dispatch({
        type: CREATE_USER_ERROR,
        payload: { msg: error.response.data.message },
      })
    }
    clearAlert()
  }

   const updatePassword = async (currentUser) => {
     dispatch({ type: UPDATE_USER_BEGIN })
    //  console.log(currentUser);
     try {
       const { data } = await authFetch.patch('/users/updateMyPassword', currentUser)
       const user = data.data.user;
       const token = data.token;
      //  console.log(data);

      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: { user, token },
      })
      addUserToLocalStorage({ user,  token })
     } catch (error) {
      //  console.log(error);
      if (error.response.status !== 401) {
        dispatch({
          type: UPDATE_USER_ERROR,
          payload: { msg: error.response.data.message },
        })
      }
    }
    clearAlert()
  }
    const createModule = async (material) => {
    // dispatch({ type: CREATE_USER_BEGIN })
    try {
       // const { name, lastName, role, email,password,passwordConfirm} = state
      //console.log(newStudent);
     // console.log(material);
      await authFetch.post('/modules', material)
        
      dispatch({ type: CLEAR_VALUES })
    } catch (error) {
      if (error.response.status === 401) return
      dispatch({
        type: CREATE_USER_ERROR,
        payload: { msg: error.response.data.message },
      })
    }
    clearAlert()
  }

  const sendEmail = async (formData) => {
    try {
      const response = await authFetch.post('/home/send-email', formData);
      // Dispatch any necessary actions based on the response

      return response; // Return the response object
    } catch (error) {
      if (error.response.status === 401) return;
      throw error; // Throw the error to be handled by the caller
    }
  };


  const getModule = async () => {
        const { modulePage,lessonCategory,lessontitle } = state
         dispatch({ type: CREATE_USER_BEGIN })
        try {
          // console.log(material);
          const { data } = await authFetch.get(`/modules?page=${modulePage}&title=${lessontitle}&category=${lessonCategory}`)
          const { modules, totalModules, numOfPagesModule } = data
        // console.log(lessonCategory, lessontitle);
         dispatch({
        type: GET_MODULES_SUCCESS,
        payload: {
          modules,
          numOfPagesModule,
          totalModules
        },
      })
    } catch (error) {
      logoutUser()
    }
    clearAlert()
  }

  const deleteModule = async (moduleId) => {
    dispatch({ type: DELETE_JOB_BEGIN })
    try {
      await authFetch.delete(`/modules/${moduleId}`)
      getModule()
    } catch (error) {
      logoutUser()
    }
  }

  const getOneModule = (id) => {
    dispatch({ type: GET_ONE_MODULE, payload: { id } })
  }

  const showStats = async () => {
    dispatch({ type: SHOW_STATS_BEGIN })
    try {
      const { data } = await authFetch('/jobs/stats')
      dispatch({
        type: SHOW_STATS_SUCCESS,
        payload: {
          stats: data.defaultStats,
          monthlyApplications: data.monthlyApplications,
        },
      })
    } catch (error) {
      logoutUser()
    }
    clearAlert()
  }
  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS })
  }
  const changePage = (page) => {
    dispatch({ type: CHANGE_PAGE, payload: { page } })
  }
  const changeModPage = (modulePage) => {
    //console.log(modulePage);
    dispatch({ type: MD_CHANGE_PAGE, payload: { modulePage } })
  }
  return <AppContext.Provider value={{
        ...state,
        displayAlert,
        setupUser,
        createUser,
        toggleSidebar,
        logoutUser,
        updatePassword,
        updateUser,
        handleChange,
        clearValues,
        createJob,
        getJobs,
        getAllJobs,
        setEditJob,
        deleteJob,
        deleteUser,
        editJob,
        showStats,
        clearFilters,
        getUsers,
        createLoanApplication,
        getLoanApplication,
        deleteLoanApplication,
        createModule,
        getModule,
        changePage,
        deleteModule,
        getOneModule,
        changeModPage,
        createTestimony,
        sendEmail,
        getTestimony,
      }}>
      {children}
    </AppContext.Provider>
  
}

const useAppContext = () => {
  return useContext(AppContext)
}

export { AppProvider, initialState, useAppContext }
