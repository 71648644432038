import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Register, Landing, Error, ProtectedRoute, TestLayout } from './pages'
import {
  AllJobs,
  Profile,
  SharedLayout,
  Stats,
  AddJob,
  MyJobs,
  AllUsers,
  ModuleDetails
} from './pages/dashboard'
import AddLesson from './pages/dashboard/AddLesson'
import AddUser from './pages/dashboard/AddUser'
import AllLessons from './pages/dashboard/AllLessons'
import BoundaryAnalysis from './pages/dashboard/BoundaryAnalysis'
//import LearningResource from './pages/dashboard/LearningResource'
import ManageLoans from './pages/dashboard/ManageLoans'
import ProtectAdminRoute from './pages/ProtectAdminRoutes'
import Hero from './components/HomePage/Hero'
import Services from './pages/services'
import ContactUs from './pages/contact'
import AddTestimony from './pages/dashboard/AddTestimony'
import Landing2 from './components/HomePage/Landing'
// import ModuleDetails from './pages/dashboard/ModuleDetails';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/test-area'
          element={
             <ProtectedRoute>
              <TestLayout />
            </ProtectedRoute>         
          }
        >
          <Route index element={<AllJobs />} />
          <Route path='job-stats' element={<Stats />} />
          <Route path='my-jobs' element={<MyJobs />} />
          <Route path='add-job' element={<AddJob />} />
          <Route path='profile' element={<Profile />} />
          <Route path='boundary-analysis' element={<BoundaryAnalysis />} />
          <Route path='manage-loans' element={<ManageLoans />} />
        </Route>


         <Route
          path='/dashboard'
          element={
             <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
             
          }
        >
          
          <Route index element={<AllLessons />} />
          <Route path="module" element={<ModuleDetails />} />
          <Route path="testimonials" element={<AddTestimony/>} />
          {/* <Route path='learning-resource' element={<LearningResource />} /> */}
          
          <Route path='profile' element={<Profile />} />
          {/* <ProtectAdminRoute> */}
          <Route path='add-students' element={
            <ProtectAdminRoute>
              <AddUser />
            </ProtectAdminRoute>
            
          } />
          <Route path='add-lessons' element={
            <ProtectAdminRoute><AddLesson /></ProtectAdminRoute>
            
          } />
          <Route path='all-students' element={
            <ProtectAdminRoute>
              <AllUsers />
            </ProtectAdminRoute>
            }/>
          {/* </ProtectAdminRoute> */}
        </Route>
        <Route path='/login' element={<Register />} />
        <Route path='/qa-squadron' element={<Landing />} />
        <Route path='/services' element={<Services />} />
        <Route path='/get-in-touch' element={<ContactUs />} />
        <Route path='/' element={<Landing2 />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
