import Wrapper from '../assets/wrappers/Navbar'
import { FaAlignLeft, FaUserCircle, FaCaretDown } from 'react-icons/fa'
import { useAppContext } from '../context/appContext'
import { NavLink } from 'react-router-dom'
import Logo from './Logo'
import { useState } from 'react'
const Navbar = () => {
  const [showLogout, setShowLogout] = useState(false)
  const { toggleSidebar, logoutUser, user } = useAppContext()
  return (
    <Wrapper>
      <div className='nav-center'>
        <button id='toggleNavbtn' type='button' className='toggle-btn' onClick={toggleSidebar}>
          <FaAlignLeft />
        </button>
          <div className='btn-container'>
          <button
            type='button'
            className='btn'
          >
                <NavLink
                id='testAreaLink'
                to='/test-area'
                className={({ isActive }) =>
                  isActive ? 'nav-link active' : 'nav-link'
                }
              >
                TestArea
          </NavLink>
              
              </button>
          </div>
        <div>
          {/* <Logo /> */}
          <div className='btn-container'>
            <button
              type='button'
              className='btn'
            >
              
                <NavLink
                id='dashboardLink'
                to='/dashboard'
                className={({ isActive }) =>
                  isActive ? 'nav-link active' : 'nav-link'
                }
              >
                Dashboard
          </NavLink>
              
              </button>
          </div>
        </div>
        <div className='btn-container'>
          <button
            id='profilebtn'
            type='button'
            className='btn'
            onClick={() => setShowLogout(!showLogout)}
          >
            <FaUserCircle />
            {user?.name}
            <FaCaretDown />
          </button>
          <div className={showLogout ? 'dropdown show-dropdown' : 'dropdown'}>
            <button id='logoutbtn' type='button' className='dropdown-btn' onClick={logoutUser}>
              logout
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Navbar
