import styled from 'styled-components'

const Wrapper = styled.article`
  .items-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.item-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  min-width: 250px;
}

.item-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.item-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.item-slug {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.item-video {
  display: block;
  max-width: 100%;
  margin-bottom: 10px;
}
.delete-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
}

`

export default Wrapper
