import { useAppContext } from '../context/appContext'
import { useEffect } from 'react'
import Loading from './Loading'
import Job from './Job'
import Wrapper from '../assets/wrappers/JobsContainer'
import PageBtnContainer from './PageBtnContainer'

const JobsContainer = () => {
  const {
    getJobs,
    jobs,
    isLoading,
    totalJobs,
    numOfPages,

  } = useAppContext()
  useEffect(() => {
    getJobs()
    // eslint-disable-next-line
  }, [])
  if (isLoading) {
    return <Loading center />
  }

  if (jobs.length === 0) {
    return (
      <Wrapper>
        <h2 id='noJobsFound'>No jobs to display...</h2>
      </Wrapper>
    )
  }

  if (jobs.length === 1) {
    return (
      <Wrapper>
        <h2 id='oneJobFound'>one Job found</h2>
        <div className='jobs'>
        {jobs.map((job) => {
          return <Job key={job._id} {...job} />
        })}
      </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <h5 id='jobsFound'>
        {totalJobs} job{jobs.length > 1 && 's'} found
      </h5>
      <div className='jobs'>
        {jobs.map((job) => {
          return <Job key={job._id} {...job} />
        })}
      </div>
      {numOfPages > 1 && <PageBtnContainer />}
    </Wrapper>
  )
}

export default JobsContainer
