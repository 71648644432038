import { FormRow, FormRowSelect } from '.'
import { useAppContext } from '../context/appContext'
import Wrapper from '../assets/wrappers/SearchContainer'

const SearchLesson = () => {
  const {
    isLoading,
    lessontitle,
    lessonCategoryOption,
    handleChange,
  } = useAppContext()
  const handleSearch = (e) => {
    if (isLoading) return
    handleChange({ name: e.target.name, value: e.target.value })
  }
//   const handleSubmit = (e) => {
//     e.preventDefault()
//     clearFilters()
//   }
  return (
    <Wrapper>
      <form className='form'>
        {/* <h4>search form</h4> */}
        <div className='form-center'>
          {/* search position */}

          <FormRow
            labelText='Search by name'
            id='lessontitle'
            type='text'
            name='lessontitle'
            placeholder='lesson title'
            value={lessontitle}
            handleChange={handleSearch}
          />

          {/* search by status */}
          <FormRowSelect
            id='lessonCategory'
            labelText='lesson Category'
            name='lessonCategory'
            handleChange={handleSearch}
            list={['All', ...lessonCategoryOption]}
          />
          {/* <button
            id='clearfilter'
            className='btn btn-block btn-success'
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Search
          </button> */}
        </div>
      </form>
    </Wrapper>
  )
}

export default SearchLesson
