
export default function Section() {
  return (
    <div className="mx-auto">
    <div className="relative isolate overflow-hidden bg-yellow-600 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">About IT Planet Solution</h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
          At IT Planet Solutions, we understand the critical role software testing plays in delivering exceptional products to market. With our extensive network of clients and industry experts, we connect businesses with skilled software testers who bring quality and reliability to their projects.
          </p>
          <div className="mt-8">
             <a
               href="/get-in-touch"
               className="inline-flex rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
             >
              Visit our help center for more info
             </a>
        </div>
        </div>
      </div>
  )
}
