import { useState } from 'react'
import { FormRow, Alert } from '../../components'
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/DashboardFormPage'




const Profile = () => {

  const initialState = {
  passwordCurrent: '',
  password: '',
  passwordConfirm: '',
}

  const [values, setValues] = useState(initialState)
  const { user, showAlert, displayAlert, updateUser, isLoading,updatePassword } =
    useAppContext()

  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [lastName, setLastName] = useState(user?.lastName)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name || !email || !lastName) {
      displayAlert()
      return
    }
    updateUser({ name, email, lastName })
    
  }
    const handleChange = (e) => {
         setValues({ ...values, [e.target.name]: e.target.value })
  }

   const changePassword = (e) => {
      e.preventDefault()
        // console.log(e.target);
        const { password, passwordCurrent, passwordConfirm} = values
    if (!password || !passwordCurrent || !passwordConfirm ) {
      displayAlert()
      return
    }
     updatePassword({ password, passwordCurrent, passwordConfirm })
    
      setTimeout(() => {
      window.location.reload();
    }, 5000); 
  }

  return (
    <Wrapper>
      <form className='form' onSubmit={handleSubmit}>
        <h3>profile</h3>
        {showAlert && <Alert />}
        <div className='form-center'>
          <FormRow
            type='text'
            name='name'
            //value={name}
            readOnly
            defaultValue ={name}

          />
          <FormRow
            type='text'
            labelText='last name'
            name='lastName'
            //value={lastName}
            readOnly
            defaultValue ={lastName}
          />
          <FormRow
            type='email'
            name='email'
            //value={email}
            readOnly
            defaultValue ={email}
          />

        </div>
      </form>

        <br/>
      <hr />
      <br/>

      <div className="changePassword">
          <form className='form' onSubmit={changePassword}>
            <h3>Change Your Password</h3>
            {/* {showAlert && <Alert />} */}
            <div className='form-center'>
            <FormRow
                id='passwordCurrent'
                labelText="Current Password"
                type='password'
                name='passwordCurrent'
                handleChange={handleChange}
              />
            <FormRow
                id='password'
                type='password'
                labelText='New Password'
                name='password'
                handleChange={handleChange}
              />
            <FormRow
                id='passwordConfirm'
                type='password'
                labelText='Confirm Password'
                name='passwordConfirm'
                
                handleChange={handleChange}
              />
              <button className='btn btn-block' type='submit' disabled={isLoading}>
                {isLoading ? 'Please Wait...' : 'update Password'}
              </button>
            </div>
            </form>
         </div>
    </Wrapper>
  )
}

export default Profile
