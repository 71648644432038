import { useAppContext } from '../context/appContext'
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi'
import Wrapper from '../assets/wrappers/PageBtnContainer'

const ModulePaginate = () => {
  const { numOfPagesModule, modulePage, changeModPage } = useAppContext()

  const pages = Array.from({ length: numOfPagesModule }, (_, index) => {
    return index + 1
  })
  const nextPage = () => {
    let newPage = modulePage + 1
    if (newPage > numOfPagesModule) {
      newPage = 1
    }
    changeModPage(newPage)
  }
  const prevPage = () => {
    let newPage = modulePage - 1
    if (newPage < 1) {
      newPage = numOfPagesModule
    }
    changeModPage(newPage)
  }
  return (
    <Wrapper>
      <button className='prev-btn' onClick={prevPage}>
        <HiChevronDoubleLeft />
        prev
      </button>
      <div className='btn-container'>
        {pages.map((pageNumber) => {
          return (
            <button
              type='button'
              className={pageNumber === modulePage ? 'pageBtn active' : 'pageBtn'}
              key={pageNumber}
              onClick={() => changeModPage(pageNumber)}
            >
              {pageNumber}
            </button>
          )
        })}
      </div>
      <button className='next-btn' onClick={nextPage}>
        next
        <HiChevronDoubleRight />
      </button>
    </Wrapper>
  )
}

export default ModulePaginate
