import { JobsContainer } from '../../components'

const MyJobs = () => {
  return (
    <>
      <JobsContainer />
    </>
  )
}

export default MyJobs
