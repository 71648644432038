import { useEffect } from "react";
import { useAppContext } from "../../context/appContext"


export default function Testimonial() {

  const { testimony, getTestimony } = useAppContext();
  
  useEffect(() => {
    getTestimony()
    // eslint-disable-next-line
  }, []);
  // console.log(testimony);
  return (
    
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-xl sm:text-center">
      <h2 className="text-lg font-semibold leading-8 mt-10 tracking-tight text-yellow-600">Testimonials</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            "Success Stories: Inspiring Testimonials from Our Graduates"
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimony.map((testimonial) => (
              <div key={testimonial._id} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                <figure className="rounded-2xl bg-blue-100 p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.description}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    {/* <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.author.imageUrl} alt="" /> */}
                    <div>
                      <div className="font-semibold text-gray-900">{testimonial.name}</div>
                      {/* <div className="text-gray-600">{`@${testimonial.author.handle}`}</div> */}
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
  )
}
