import { FormRow, FormRowSelect } from '.'
import { useAppContext } from '../context/appContext'
import Wrapper from '../assets/wrappers/SearchContainer'

const SearchContainer = () => {
  const {
    isLoading,
    search,
    searchStatus,
    searchType,
    sort,
    sortOptions,
    handleChange,
    priceOptions,
    clearFilters,
    jobTypeOptions,
    statusOptions,
    salary,
    place
  } = useAppContext()
  const handleSearch = (e) => {
    if (isLoading) return
    handleChange({ name: e.target.name, value: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    clearFilters()
  }
  return (
    <Wrapper>
      <form className='form'>
        <h4>search form</h4>
        <div className='form-center'>
          {/* search position */}

          <FormRow
            labelText='Job Title'
            id='jobtitle'
            type='text'
            name='search'
            value={search}
            handleChange={handleSearch}
          />
          <FormRow
            labelText='Job Location'
            id='place'
            type='text'
            name='place'
            value={place}
            handleChange={handleSearch}
          />
          {/* search by status */}
          <FormRowSelect
            id='status'
            labelText='status'
            name='searchStatus'
            value={searchStatus}
            handleChange={handleSearch}
            list={['all', ...statusOptions]}
          />
          {/* search by type */}
          <FormRowSelect
            id='type'
            labelText='type'
            name='searchType'
            value={searchType}
            handleChange={handleSearch}
            list={['all', ...jobTypeOptions]}
          />
          {/* sort */}
          {/* <FormRow
            labelText='Job Location'
            id='joblocation'
            type='text'
            name='location'
            value={location}
            handleChange={handleSearch}
            // list={sortOptions}
          /> */}
          
            {/* sort by price */}
          <FormRowSelect
            id='sort'
            name='sort'
            value={sort}
            handleChange={handleSearch}
            list={sortOptions}
          />
             {/* location */}
          <FormRowSelect
            id='salarysearch'
            name='salary'
            value={salary}
            handleChange={handleSearch}
            list={['all', ...priceOptions]}
          />
          <button
            id='clearfilter'
            className='btn btn-block btn-danger'
            disabled={isLoading}
            onClick={handleSubmit}
          >
            clear filters
          </button>
        </div>
      </form>
    </Wrapper>
  )
}

export default SearchContainer
