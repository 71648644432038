import { FormRow, Alert, FormRowSelect } from '../../components'
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/DashboardFormPage'
import { useState, useEffect } from 'react'
import React from 'react';

function generatePassword(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&';
  let password = 'password';
 // for (let i = 0; i < length; i++) {
 //   const randomIndex = Math.floor(Math.random() * chars.length);
  //  password += chars[randomIndex];
//  }
  return password;
}

const AddUser = () => {
  const {
    isLoading,
    isEditing,
    showAlert,
    displayAlert,
    courseOptions,
    clearValues,
    createUser,
    editJob,
      roleOptions,
      
    } = useAppContext()
    
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
     const [password, setPassword] = useState('password');
    // const [studentConfirmPassword, setStudentConfirmPassword] = useState('');
    const [course, setCourse] = useState('all');
  const [role, setRole] = useState('student');
  useEffect(() => {
    setPassword(generatePassword(8));
  }, []);

  const onSubmit = (e) => {
     // setPassword(generatePassword(8));
        let password = '';
        let passwordConfirm = '';
    e.preventDefault()

    if (!name || !lastName || !email) {
      displayAlert()
      return
        }//p/P^pD%c
      password = name;
      passwordConfirm = password;
      //console.log(password);
        const newStudent = { name, lastName, email,password,passwordConfirm ,role,course}
    if (isEditing) {
      editJob()
      return
    }
       createUser({
           newStudent,
           alertText: 'User Created! Redirecting...'
       }
      )
      setName('');
      setLastName('');
      setEmail('');
  }


  return (
    <Wrapper>
      <form className='form' onSubmit={onSubmit}>
        <h3>{isEditing ? 'edit job' : 'Add A New Student'}</h3>
        {showAlert && <Alert />}
        <div className='form-center'>
           {/* name input */}
            <FormRow
            labelText='First Name'
            id="name"
            type='text'
            name='name'
            value={name}
            handleChange={(e) => {setName(e.target.value)}}
            />
                  
            {/* name input */}
            <FormRow
            labelText='Last Name'
            id="lastName"
            type='text'
            name='lastName'
            value={lastName}
            handleChange={(e) => {setLastName(e.target.value)}}
          />

        {/* email input */}
        <FormRow
          labelText='Student Email'
          id="email"
          type='email'
          name='email'
          handleChange={(e) => {setEmail(e.target.value)}}
        />
        {/* password input */}
        {/* <FormRow
          labelText='student Password'
          id="password"
          type='password'
          name='studentPassword'
          value={studentPassword}
          handleChange={(e) => {setStudentPassword(e.target.value)}}
        /> */}
          {/* <FormRow
            id="passwordConfirm"
            labelText="Confirm Password"
            type='password'
            name='studentConfirmPassword'
            value={studentConfirmPassword}
            handleChange={(e) => {setStudentConfirmPassword(e.target.value)}}
                  /> */}
            <FormRowSelect
            id="role"
            labelText="Role"
            type='text'
            name='role'
            value={role}
            list={roleOptions}
            handleChange={(e) => {setRole(e.target.value)}}
                  />
                  
            <FormRowSelect
            id="course"
            labelText="Course"
            type='text'
            name='course'
            value={course}
            list={courseOptions}
            handleChange={(e) => {setCourse(e.target.value)}}
          />

          {/* btn container */}
          <div className='btn-container'>
            <button type='submit' className='btn btn-block' disabled={isLoading}>
            submit
            </button>
            <button
              className='btn btn-block clear-btn'
              onClick={(e) => {
                e.preventDefault()
                clearValues()
              }}
            >
              clear
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  )
}

export default AddUser
