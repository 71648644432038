export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'


export const GET_LOANS_SUCCESS ='GET_LOANS_SUCCESS'
export const GET_LOANS_BEGIN = 'GET_LOANS_BEGIN'
export const CREATE_LOAN_SUCCESS ='CREATE_LOAN_SUCESS'
export const CREATE_LOAN_ERROR = 'CREATE_LOAN_ERROR'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const LOGOUT_USER = 'LOGOUT_USER'

export const  GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS'
export const  GET_MODULES_BEGIN ='GET_MODULES_BEGIN'

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const CLEAR_VALUES = 'CLEAR_VALUES'

export const CREATE_JOB_BEGIN = 'CREATE_JOB_BEGIN'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR'

export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const GET_JOBS_BEGIN = 'GET_JOBS_BEGIN'
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS'

export const GET_USERS_BEGIN = 'GET_USERS_BEGIN'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'

export const GET_ONE_MODULE = 'GET_ONE_MODULE'

export const SET_EDIT_JOB = 'SET_EDIT_JOB'

export const DELETE_JOB_BEGIN = 'DELETE_JOB_BEGIN'

export const EDIT_JOB_BEGIN = 'EDIT_JOB_BEGIN'
export const EDIT_JOB_SUCCESS = 'EDIT_JOB_SUCCESS'
export const EDIT_JOB_ERROR = 'EDIT_JOB_ERROR'

export const SHOW_STATS_BEGIN = 'SHOW_STATS_BEGIN'
export const SHOW_STATS_SUCCESS = 'SHOW_STATS_SUCCESS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const MD_CHANGE_PAGE = 'MD_CHANGE_PAGE'
export const GET_TESTIMONY_BEGIN = 'GET_TESTIMONY_BEGIN'
export const GET_TESTIMONY_SUCCESS ='GET_TESTIMONY_SUCCESS'

