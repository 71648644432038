
import Section from './Section'
import Testimonial from './Testimonial'
import Landing from './../../assets/images/landing.PNG'



export default function Hero() {

    return (
       <>
    <div className="bg-white">
    <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <div className="hidden sm:mb-10 sm:flex">
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                <span className="text-blue-400">IT</span> <span className="text-yellow-600">Planet</span> <span class="text-blue-400">Solution</span>
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                Your gateway to software testing excellence.
                We specialize in sourcing software testing contracts and training the industry's best testers at our renowned Software Testing Academy
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a href="/services" className="text-sm font-semibold leading-6 text-gray-900">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src={Landing}
            alt=""
          />
        </div>
      </div>
      </div>
        <Section />
        <Testimonial/>
     </>
  )
}
