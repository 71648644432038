import { IoBarChartSharp } from 'react-icons/io5'
import { MdQueryStats } from 'react-icons/md'
import { FaWpforms,FaMoneyBill } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'

const joblinks = [
  { id: 1, text: 'all jobs', path: '/test-area', icon: <MdQueryStats /> },
  { id: 2, text: 'My jobs', path: 'my-jobs', icon: <MdQueryStats /> },
  { id: 3, text: 'add job', path: 'add-job', icon: <FaWpforms /> },
  { id: 4, text: 'my Job stats', path: 'job-stats', icon: <IoBarChartSharp /> },
   { id: 5, text: 'Loan Application', path: 'boundary-analysis', icon: <FaMoneyBill /> },
   { id: 6, text: 'Manage Applied Loans', path: 'manage-loans', icon: <FaMoneyBill /> },
 
  
]

const profileLinks = [
  { id: 1, text: 'all lesson', path: '/dashboard', icon: <MdQueryStats /> },
  //{ id: 2, text: 'Learning resource', path: 'learning-resource', icon: <MdQueryStats /> },
  { id: 2, text: 'profile', path: 'profile', icon: <ImProfile /> },
   { id: 3, text: 'Leave Testimonial', path: 'testimonials', icon: <FaWpforms /> },
]

const modulelinks = [
  //{ id: 1, text: 'Learning resource', path: 'learning-resource', icon: <MdQueryStats /> },
  { id: 2, text: 'all lessons', path: '/dashboard', icon: <MdQueryStats /> },
  { id: 3, text: 'add lesson/Learnig Resource', path: 'add-lessons', icon: <FaWpforms /> },
  //  { id: 3, text: 'view', path: '/module', icon: <FaWpforms /> },
]

const userlinks = [
  { id: 1, text: 'all students', path: 'all-students', icon: <MdQueryStats /> },
  { id: 2, text: 'add students', path: 'add-students', icon: <FaWpforms /> },
]


const adminlinks = [
  { id: 1, text: 'profile', path: 'profile', icon: <FaWpforms /> },
  { id: 2, text: 'testimonials', path: 'testimonials', icon: <FaWpforms /> },
]


export { joblinks, profileLinks,modulelinks, userlinks,adminlinks}

