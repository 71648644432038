import {adminlinks, modulelinks, profileLinks, userlinks} from '../utils/links'
import { NavLink } from 'react-router-dom'
import { useAppContext } from '../context/appContext'

const ProfileLinks = ({ toggleSidebar }) => {
    const { user } = useAppContext()
    if (user.role === 'admin')
    {
    return (
      <>
    <div className='nav-links'>
      {adminlinks.map((link) => {
        const { text, path, id, icon } = link

        return (
          <NavLink
            id={path}
            to={path}
            key={id}
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink>
        )
      })}
      </div>
      
      <div className='nav-links'>
      {modulelinks.map((link) => {
        const { text, path, id, icon } = link

        return (
          <NavLink
            id={path}
            to={path}
            key={id}
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink>
        )
      })}
            </div>
            
              <div className='nav-links'>
      {userlinks.map((link) => {
        const { text, path, id, icon } = link

        return (
          <NavLink
            id={path}
            to={path}
            key={id}
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink>
        )
      })}
    </div>
    </>
  )     
    }
     return (
    <div className='nav-links'>
      {profileLinks.map((link) => {
        const { text, path, id, icon } = link

        return (
          <NavLink
            id={path}
            to={path}
            key={id}
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink>
        )
      })}
        </div>
     )
}

export default ProfileLinks
