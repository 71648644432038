import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/DashboardFormPage'
import PageBtnContainer from '../../components/PageBtnContainer'
import {  useEffect } from 'react'

const AllUsers = (props) => {
   const {
       getUsers,
     users,
     totalUsers,
       deleteUser,
       numOfPages,
  } = useAppContext()
  useEffect(() => {
    getUsers()
    // eslint-disable-next-line
  }, [])
//  const [users, setUsers] = useState(props.users);

function handleDelete(id) {
  const confirmed = window.confirm("Are you sure you want to delete this user? This user will be deleted permanently");
  if (confirmed) {
    deleteUser(id);
  }
}

return (
  <div className="px-4 sm:px-6 lg:px-8">
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">{totalUsers } Students</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the users in your account including their name, title, email and role.
        </p>
      </div>
      {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add user
        </button>
      </div> */}
    </div>
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                  First Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Last Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Email
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Manual or Auto
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Role
                </th>
               
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {users.map((person) => (
                <tr key={person.email} className="even:bg-gray-50">
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {person.name}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {person.lastName}
                  </td>
                
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.email}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.course}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                  <button className="rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"  onClick={() => ( handleDelete(person._id))}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {numOfPages > 1 && <PageBtnContainer />}
        </div>
      </div>
    </div>
  </div>
)



  //   return (
  //     <Wrapper>
  //       <h1>{totalUsers } Students</h1>
  //   <table>
  //     <thead>
  //           <tr>
  //         <th></th>
  //         <th>Name</th>
  //         <th>Last Name</th>
  //         <th>Email</th>
  //         <th>Course</th>
  //         <th>Role</th>
  //         <th>Actions</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {users.map((user, index) => (
  //         <tr key={index}>
  //           <td>{index + 1}</td>
  //           <td>{user.name}</td>
  //           <td>{user.lastName}</td>
  //           <td>{user.email}</td>
  //           <td>{user.course}</td>
  //           <td>{user.role}</td>
  //           <td>
  //             {/* <button onClick={() => handleEdit(user.id)}>Edit</button> */}
  //             <button className="delete-btn"  onClick={() => ( handleDelete(user._id))}>Delete</button>
  //           </td>
  //         </tr>
  //       ))}
  //     </tbody>
  //   </table>
  //   </Wrapper>
  // )
  
}

export default AllUsers
