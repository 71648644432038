import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../context/appContext';
import moment from 'moment';
import Wrapper from '../../assets/wrappers/BoundaryAnalysis';

const ManageLoans = () => {
  const { getLoanApplication, loans, deleteLoanApplication } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [loanList, setLoanList] = useState(loans); // Initialize with initial value of loans
  const len = loans.length

  useEffect(() => {
    getLoanApplication();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (loanId) => {
    try {
      await deleteLoanApplication(loanId);
      // Remove the deleted loan application from the loans array
      const updatedLoans = loanList.filter((loan) => loan._id !== loanId);
      setLoanList(updatedLoans);
    } catch (error) {
      console.log('Error deleting loan application:', error);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Wrapper>
      <div className="formbold-main-wrapper">
        <div className="formbold-form-wrapper">
          <h1 id='numApplications'>{`${len} Application`}</h1>
          <table className="formbold-table">
            <thead>
              <tr>
                <th>Applicant</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Employment</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {loanList.map((loanApplicant) => (
                <tr id={loanApplicant.lastName} key={loanApplicant._id}>
                  <td>{`${loanApplicant.firstName} ${loanApplicant.lastName}`}</td>
                  <td>{moment(loanApplicant.createdAt).format('MMM Do, YYYY')}</td>
                  <td>{`£${loanApplicant.loanAmount}`}</td>
                  <td>{loanApplicant.employment}</td>
                  <td
                    className={
                      loanApplicant.status === 'Approved'
                        ? 'status-approved'
                        : loanApplicant.status === 'Pending'
                        ? 'status-pending'
                        : 'status-declined'
                    }
                  >
                    {loanApplicant.status}
                  </td>
                  <td>
                    <button className="btn btn-block" onClick={() => handleDelete(loanApplicant._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

export default ManageLoans;
