import React, { useState } from "react";
import Wrapper from '../../assets/wrappers/BoundaryAnalysis'
import { useAppContext } from "../../context/appContext";


const AddLessons = () => {
    const { createModule } = useAppContext();
     const [formData, setFormData] = useState({
    title: "",
    description: "",
    videoLink: "",
    readingLink:"",
    category:"Learning Material"
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    const resetForm = () => {
        setFormData({
            title: "",
            description: "",
            videoLink: "",
            readingLink:"",
            category:"Learning Material"
        });
}

  const handleSubmit = (event) => {
      event.preventDefault();
      createModule(formData);
        resetForm()
  };

  return (
    <Wrapper>
   <div className="formbold-main-wrapper">
      <div className="formbold-form-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="title" className="formbold-form-label">
                Title *
              </label>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Title"
                className="formbold-form-input"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
                <div>
              <label htmlFor="title" className="formbold-form-label">
                Category *
              </label>
              <select
                name="category"
                id="category"
                className="formbold-form-input"
                value={formData.category}
                onChange={handleChange}
            >
                <option value="">Select</option>
                <option value="Weekly-lesson">Weekly Lesson</option>
                <option value="Learning-material">Learning Resource</option>
                </select>
            </div>
            </div>
                <div>
              <label htmlFor="videoLink" className="formbold-form-label">
                Video Link
              </label>
              <input
                type="text"
                name="videoLink"
                id="videoLink"
                placeholder="Video Link"
                className="formbold-form-input"
                value={formData.videoLink}
                onChange={handleChange}
              />
                      </div>
                           <div>
              <label htmlFor="readingLink" className="formbold-form-label">
                Reading Link
              </label>
              <input
                type="text"
                name="readingLink"
                id="readingLink"
                placeholder="Reading Link e.g w3school, tutorialpoint"
                className="formbold-form-input"
                value={formData.readingLink}
                onChange={handleChange}
              />
            </div>
          <div>
              <label htmlFor="description" className="formbold-form-label">
                Description *
              </label>
                <textarea
                rows="14"
                type="text"
                name="description"
                id="description"
                placeholder="Description"
                className="formbold-form-input"
                value={formData.description}
                onChange={handleChange}
              />
            </div>
         
          <button type="submit" className="formbold-btn">
            Add
          </button>
        </form>
      </div>
    </div>
    </Wrapper>
  )
}

export default AddLessons
